<template>
    <div>
       <headerv-2></headerv-2>
       <div class="container mx-auto py-10 lg:py-20">
      <div class="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
        <div class="flex flex-col overflow-y-auto md:flex-row">
          <div class="h-32 md:h-auto md:w-1/2">
          <img
            aria-hidden="true"
            class="object-cover w-full h-full dark:hidden"
            src="/images/banner.jpg"
            alt="Office"
          />
          <img
            aria-hidden="true"
            class="hidden object-cover w-full h-full dark:block"
            src="/images/banner.jpg"
            alt="Office"
          />
        </div>
        <div class="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
          <div class="w-full">
            <h2 class="text-lg font-semibold">Reset Password</h2>
            <a-form
              id="components-form-demo-normal-login"
              :form="form"
              class="login-form"
              @submit.prevent="handleSubmit"
            
            >
              <a-form-item class="group-input">
                <label for="username">New Password *</label>
                <a-input-password
                  v-decorator="[
                    'password',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                        {
                          min: 8,
                          message: 'Password must be 8 characters or more',
                        },
                      ],
                    },
                  ]"
                  autocomplete="off"
                ></a-input-password>
              </a-form-item>        
              <button
                type="submit"
                class="flex items-center justify-center gap-2 w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-red-500 hover:bg-black focus:outline-none focus:shadow-outline-purple"
              >
                Reset Password
                <template v-if="loading">
                  <loaderVue />
                </template>
              </button>
            </a-form>
            <hr class="my-2" />
            <p class="mt-1">
              <a class="text-sm font-medium text-red-500 hover:underline hover:text-black" href="/sign-in">
                Back To Login
              </a>
            </p>
          </div>
        </div>
      </div>
      </div>
    </div>
        <Footerv2></Footerv2>
    </div>
    </template>
    
    <script>        
    import ForgotPasswordForm from '../components/forms/ForgotPasswordForm.vue';
    import {mapState} from "vuex"
import { auth } from "../database";
import { confirmPasswordReset } from "firebase/auth";
import router from "../router";
import Headerv2 from '../v2/Headerv2.vue';
import Footerv2 from "../v2/Footerv2.vue";
  
    export default {
        components:{ForgotPasswordForm, Headerv2, Footerv2 },
        data() {
    return {
      // Binded model property for "Sign In Form" switch button for "Remember Me" .
      rememberMe: true,
      email:""
    };
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  computed:{
...mapState(["loading"])
  },
  methods: {
    // Handles input validation after submission.
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store.dispatch("resetPassword", values)
          const oobCode = this.$route.query.oobCode;
          confirmPasswordReset(auth,oobCode,values.password).then(()=>{
            router.push('/sign-in')
          }).catch((err)=>{
          })
          
        }
      });
    },
  },
    
    }
    </script>
    
    <style>
    
    </style>