var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('headerv-2'),_c('div',{staticClass:"container mx-auto py-10 lg:py-20"},[_c('div',{staticClass:"flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800"},[_c('div',{staticClass:"flex flex-col overflow-y-auto md:flex-row"},[_vm._m(0),_c('div',{staticClass:"flex items-center justify-center p-6 sm:p-12 md:w-1/2"},[_c('div',{staticClass:"w-full"},[_c('h2',{staticClass:"text-lg font-semibold"},[_vm._v("Reset Password")]),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',{staticClass:"group-input"},[_c('label',{attrs:{"for":"username"}},[_vm._v("New Password *")]),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'password',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                    {
                      min: 8,
                      message: 'Password must be 8 characters or more',
                    } ],
                } ]),expression:"[\n                'password',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please input your password!',\n                    },\n                    {\n                      min: 8,\n                      message: 'Password must be 8 characters or more',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"autocomplete":"off"}})],1),_c('button',{staticClass:"flex items-center justify-center gap-2 w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-red-500 hover:bg-black focus:outline-none focus:shadow-outline-purple",attrs:{"type":"submit"}},[_vm._v(" Reset Password "),(_vm.loading)?[_c('loaderVue')]:_vm._e()],2)],1),_c('hr',{staticClass:"my-2"}),_vm._m(1)],1)])])])]),_c('Footerv2')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-32 md:h-auto md:w-1/2"},[_c('img',{staticClass:"object-cover w-full h-full dark:hidden",attrs:{"aria-hidden":"true","src":"/images/banner.jpg","alt":"Office"}}),_c('img',{staticClass:"hidden object-cover w-full h-full dark:block",attrs:{"aria-hidden":"true","src":"/images/banner.jpg","alt":"Office"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"mt-1"},[_c('a',{staticClass:"text-sm font-medium text-red-500 hover:underline hover:text-black",attrs:{"href":"/sign-in"}},[_vm._v(" Back To Login ")])])}]

export { render, staticRenderFns }